// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-colors-js": () => import("./../../../src/pages/colors.js" /* webpackChunkName: "component---src-pages-colors-js" */),
  "component---src-pages-company-terms-js": () => import("./../../../src/pages/company-terms.js" /* webpackChunkName: "component---src-pages-company-terms-js" */),
  "component---src-pages-contact-page-cover-js": () => import("./../../../src/pages/contact-page-cover.js" /* webpackChunkName: "component---src-pages-contact-page-cover-js" */),
  "component---src-pages-contact-page-js": () => import("./../../../src/pages/contact-page.js" /* webpackChunkName: "component---src-pages-contact-page-js" */),
  "component---src-pages-contact-sidebar-map-js": () => import("./../../../src/pages/contact-sidebar-map.js" /* webpackChunkName: "component---src-pages-contact-sidebar-map-js" */),
  "component---src-pages-illustrations-js": () => import("./../../../src/pages/illustrations.js" /* webpackChunkName: "component---src-pages-illustrations-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-not-found-cover-js": () => import("./../../../src/pages/not-found-cover.js" /* webpackChunkName: "component---src-pages-not-found-cover-js" */),
  "component---src-pages-social-media-js": () => import("./../../../src/pages/social-media.js" /* webpackChunkName: "component---src-pages-social-media-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-web-development-js": () => import("./../../../src/pages/web-development.js" /* webpackChunkName: "component---src-pages-web-development-js" */)
}

